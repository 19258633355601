import React from 'react';
import styled from "styled-components";
import {colors} from "../constants";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  height: 52px;
  align-items: center;
  justify-content: start;
  max-width: 100%;
  min-width: 100px;
  -webkit-box-shadow: 0px 0px 29px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 29px -4px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 29px -4px rgba(0,0,0,0.75);
  background-color: white;
  margin: 6px;
  &:hover {
    background-color: ${colors.secondary};
  }
`;

const StyledA = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function LibraryCard({ url, title }) {
  return (
    <StyledDiv>
      <StyledA href={url} target="_blank" rel={"noopener noreferrer"}><img src={process.env.PUBLIC_URL + 'images/github-logo.png'} alt="github logo" style={{ width: 32 }}/></StyledA>
      <h3 style={{ textAlign: 'start', marginLeft: 8 }}>{title}</h3>
    </StyledDiv>
  );
}

export default LibraryCard;
