import React, {useEffect, useRef, useState} from 'react';
import WordController from 'word-controller';
import "./WordControllerPage.css";

let defaultText = "Lorem ipsum dolar amet lorem ipsum dolar.";

function WordControllerPage() {
  const ref = useRef();
  const [minWordCountInLine, setMinWordCount] = useState(3);
  const [text, setText] = useState(defaultText);

  useEffect(() => {
    new WordController(ref.current, text, { minWordCountInLine: minWordCountInLine });
  }, [ref, text, minWordCountInLine]);

  return (
    <div>
      <h1>Word Controller</h1>
      <div className={"WordControllerPage__card"}>
        <div className={"WordControllerPage__formInputContainer"}>
          <div style={{ width: 245 }}>
            <div className={"WordControllerPage__inputLabel"}>Minimum word count in line</div>
            <input className={"WordControllerPage__input"} value={minWordCountInLine} onChange={e => setMinWordCount(e.target.value)}/>
          </div>
          <div style={{ width: 245, marginTop: 10 }}>
          <div className={"WordControllerPage__inputLabel"}>Text</div>
          <textarea className={"WordControllerPage__inputTextArea"} value={text} onChange={e => setText(e.target.value)}/>
          </div>
        </div>
        <div className={"WordControllerPage__normalContainer"} style={{ width: 280 }}>
          {text}
          <span className={"WordControllerPage__title"}>Initial</span>
        </div>
        <div style={{ position: "relative", marginTop: 27 }}>
          <div className={"WordControllerPage__container"} ref={ref} style={{ width: 280 }}/>
          <span className={"WordControllerPage__title"} style={{ marginLeft: 20 }}>WordController</span>
        </div>
      </div>
    </div>
  );
}

export default WordControllerPage;
