import React from "react";
import NavBar from "../components/NavBar";
import styled from "styled-components";
import {colors} from "../constants";
import LibraryCard from "../components/LibraryCard";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 1040px;
  max-width: 100%; 
`;

const USED_LIBRARY_CONFIGS = [
  {
    url: '',
    title: 'antd',
  },
  {
    url: '',
    title: 'bizcharts',
  },
  {
    url: '',
    title: 'react-grid-layout',
  },
  {
    url: '',
    title: 'paperjs',
  },
  {
    url: '',
    title: 'cropperjs',
  },
  {
    url: '',
    title: 'react-cropper',
  },
  {
    url: '',
    title: 'styled-components',
  },
  {
    url: '',
    title: 'react-redux',
  },
  {
    url: '',
    title: 'redux-saga',
  },

  {
    url: '',
    title: 'immer',
  },
  {
    url: '',
    title: 'ramda',
  },
  {
    url: '',
    title: 'lodash',
  },
  {
    url: '',
    title: 'react-i18next',
  },
  {
    url: '',
    title: 'i18next',
  },
  {
    url: '',
    title: 'react-router-dom',
  },
  {
    url: '',
    title: 'moment',
  },
  {
    url: '',
    title: 'date-fns',
  },
  {
    url: '',
    title: 'jsdoc',
  },
  {
    url: '',
    title: 'node-sass',
  },
  {
    url: '',
    title: 'formik',
  },
  {
    url: '',
    title: 'react-icons',
  },
  {
    url: '',
    title: 'axios',
  },
  {
    url: '',
    title: 'react-pdf',
  },
  {
    url: '',
    title: 'blueprint',
  },
  {
    url: '',
    title: 'react-ace',
  },
  {
    url: '',
    title: 'firebase',
  },
  {
    url: '',
    title: 'react-router-page-transition',
  },
  {
    url: '',
    title: 'graphql',
  },
  {
    url: '',
    title: 'apollo',
  },
  {
    url: '',
    title: 'cypress',
  },
  {
    url: '',
    title: 'puppeteer',
  },
  {
    url: '',
    title: 'phantomjs',
  },
  {
    url: '',
    title: 'pigeon-maps',
  },
  {
    url: '',
    title: 'bulma',
  },
  {
    url: '',
    title: 'json-logic',
  },
  {
    url: '',
    title: 'pdfmake',
  },
  {
    url: '',
    title: 'react-flip-toolkit',
  },
  {
    url: '',
    title: 'react-joyride',
  },
  {
    url: '',
    title: 'react-sizeme',
  },
  {
    url: '',
    title: 'react-sortable-tree',
  },
  {
    url: '',
    title: 'react-idle-timer',
  },
  {
    url: '',
    title: 'react-dnd',
  },
  {
    url: '',
    title: 'commitizen',
  },
  {
    url: '',
    title: 'prettier',
  },
  {
    url: '',
    title: 'husky',
  },
  {
    url: '',
    title: 'lunr',
  },
  {
    url: '',
    title: 'p5.js'
  },
  {
    url: '',
    title: 'three.js',
  },
  {
    url: '',
    title: 'react-hook-form',
  }
]

function UsedLibraries () {
  return (
    <div style={{
      width: '100%',
      minHeight: '100vh',
      // overflowY: 'scroll',
      backgroundColor: colors.primary,
    }}>
      <NavBar/>
      <main id={"mainContent"}>
        <Container>
          <ListContainer>
            {USED_LIBRARY_CONFIGS.map(({ url, title }) => <LibraryCard key={title} url={url} title={title}/>)}
          </ListContainer>
        </Container>
      </main>
    </div>
  );
}

export default UsedLibraries;
