import React from 'react';

function DeSurProjectInfo() {
  return (
    <div>
      <img src={process.env.PUBLIC_URL + 'images/de_sur/first.jpeg'} style={{ maxWidth: '100%' }} alt={"de_sur map design building"}/>
      <img src={process.env.PUBLIC_URL + 'images/de_sur/radar.jpg'} style={{ maxWidth: '100%' }} alt={"de_sur radar design"}/>
    </div>
  );
}

export default DeSurProjectInfo;
