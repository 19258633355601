import React from 'react';

function ProjectInfoDisplay({ project }) {
  const {
    title,
    body,
  } = project;
  return (
    <>
      <h3>{title}</h3>
      <p>{body}</p>
    </>
  );
}

export default ProjectInfoDisplay;
