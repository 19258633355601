import React from 'react';
import {colors} from "../constants";

const buttonStyle = (isSelected, isFirst, isLast, color) => ({
  padding: 10,
  margin: 0,
  // outline: 'none',
  backgroundColor: isSelected ? (color ?? colors.info) : 'white',
  color: isSelected ? 'white' : colors.primary,
  borderColor: color ?? colors.info,
  borderStyle: 'solid',
  borderWidth: 1,
  borderLeftWidth: 0,
  cursor: 'pointer',
  ...(isFirst ? {
    borderLeftWidth: 1,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  } : {}),
  ...(isLast ? {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  } : {}),
});

function ButtonGroup({ selectedKey, options, color, style, onClick }) {
  return (
    <div style={style}>
      {options.map((option, i) => <button
        key={option.key}
        type={"button"}
        style={buttonStyle(selectedKey === option.key, i === 0, i === options.length - 1, color)}
        onClick={(event) => onClick(option, event)}>
        {option.label}
      </button>)}
    </div>
  );
}

export default ButtonGroup;
