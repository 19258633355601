import React from 'react';
import NavBar from "../components/NavBar";
import styled from "styled-components";
import {colors} from "../constants";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5vh;
`;

const TextContainer = styled.div`
  width: 1040px;
  padding: 10px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
`;

function About(props) {
  return (
    <div style={{
      minHeight: '100vh',
      backgroundColor: colors.primary,
      color: "white",
    }}>
      <NavBar/>
      <main id={"mainContent"}>
        <Container>
          <TextContainer>
            <h2>About</h2>
            <div>
              Hello, I’m Ismail! I’m a software engineer working mostly with web and react. I am living in Istanbul and studied computer science in Istanbul Technical University.
            </div>
          </TextContainer>
        </Container>
      </main>
    </div>
  );
}

export default About;
