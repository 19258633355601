import React, {useCallback, useState} from 'react';
import {colors, PROJECT_KEYS} from "../constants";
import ButtonGroup from "./ButtonGroup";
import ProjectInfoDisplay from "./ProjectInfoDisplay";
import {useStateWithLocalStorage} from "../hooks";
import Spinner from "./Spinner";
import ProjectNotes from "./ProjectNotes";

const MOBILE_TECH_TYPES = {
  REACTNATIVE: 'REACTNATIVE',
  IOS: 'IOS',
};

const MOBILE_PROJECT_INFO_MAPPED_BY_PROJECT_KEY = {
  [PROJECT_KEYS.ADDWISE]: {
    title: 'Addwise',
    body: "Addwise is a social media application where users can do basic social media activities like posting, liking, etc. Addwise is an iOS based mobile social media application which you can give an advice or ask for an advice. It has a basic social media features. You can share your questions and wait for response from other users in the groups you are in. Also group admins can make an announcement to users in its specific tab with a notification. Firebase is used as a backend service. We have over 20 tables in that database. We are also using Firebase for its other services like 'Authentication', 'Storage' and 'Notification' center.",
    type: 'img',
    src: process.env.PUBLIC_URL + '/videos/addwise-demo.gif'
  },
  [PROJECT_KEYS.ISHELF]: {
    title: 'iShelf',
    body: 'iShelf is an iOS based mobile social media application which you can give a movie advices to your friends easily. This idea come up to me when I was watching one of my favorite tv shows, Jerry Seinfeld. In one episode, one of the characters was choosing a movie from a shelf which shelf is owned by some critic. In the store, every movie critic owns a shelf that they can put their favorite movies there to customers to see. So, I thought we can apply the same logic in a mobile application and I developed iShelf. Every user can add a movie to their shelf and share their thoughts about the movie. Developed with Swift 3.0 and used firebase as a backend service.',
    type: 'img',
    src: process.env.PUBLIC_URL + '/videos/ishelf-demo.gif'
  },
  [PROJECT_KEYS.TRT_HABER]: {
    title: 'TRT Haber',
    body: 'TRT is the national public broadcaster of Turkey. I developed this application for them when I was working to Erstream company. This is my first react-native application. It displays news feed. Some of the things that I use in here is infinite scroll, carousel, video player, image gallery, drawer.',
    type: 'img',
    src: process.env.PUBLIC_URL + '/videos/trt-haber-demo.gif'
  },
  [PROJECT_KEYS.FILMBOX]: {
    title: 'Filmbox',
    body: 'I worked with designers in this application. We were using Zeplin at that time to communicate about the design. The application has a different behavior than usual. It is capable of rendering different UI determined by the backend. It has limitations though but It can render different number or rows, columns and different shapes to the home page. The other features are video player with customized UI, image carousel, pagination.',
    type: 'img',
    src: process.env.PUBLIC_URL + '/videos/filmbox-demo.gif'
  },
  [PROJECT_KEYS.SHRILLING_CHICKEN]: {
    title: 'Shrilling Chicken',
    body: 'This is small funny application that users can tap on a chicken and produce some chicken noises. :D Swift 3.0 and Xcode 8.0 is used for developing the mobile application. Images are created in Adobe Photoshop CS5.',
    type: 'img',
    src: process.env.PUBLIC_URL + '/videos/shrilling-chicken-demo.gif'
  },
  [PROJECT_KEYS.GOTUR]: {
    title: 'Götür',
    body: "Götür is developed in 'Semih Öztürk Hackathon' which is organized by Getir/Bitaksi. We worked as a team of 5 and our project got the 3rd place. Götür is a location based application where users can add their packages and destination address so that courier around the package can deliver it to destination point. MongoDB is used as a backend and Swift 3.0 and Xcode 8.0 is used for developing the mobile application. We also added Firebase Notification service!",
    type: 'img',
    src: process.env.PUBLIC_URL + '/videos/gotur-demo.gif'
  },
  [PROJECT_KEYS.LANDMARK_RECOGNITION]: {
    title: 'Landmark recognition',
    body: "Landmark Recognition is iOS based application which is developed in OBSS Hackathon. Our aim is that if user wants to learn more about a specific building, they will upload the image of the building and our server will respond with an information of the building. It is designed to be prototype so server does not know every building. We trained a model to recognize buildings and if uploaded building is found we are displaying its data from wikipedia.",
    type: '',
  },
  [PROJECT_KEYS.PROENTE]: {
    title: 'Proente',
    body: `
    I developed additional
    features for a mobile application which is written in react-native by reading and
    understanding the legacy code.
    `,
    type: 'hidden',
  }
};

const boxShadow = {
  'WebkitBoxShadow': '0px 0px 29px -4px rgba(0,0,0,0.75)',
  'MozBoxShadow': '0px 0px 29px -4px rgba(0,0,0,0.75)',
  'boxShadow': '0px 0px 29px -4px rgba(0,0,0,0.75)',
};

// const MOBILE_PROJECT_CONFIG_MAPPED_BY_KEY = {
//   [PROJECT_KEYS.TRT_HABER]: {
//     src: process.env.PUBLIC_URL + '/videos/trt-haber-demo.gif'
//   },
//   [PROJECT_KEYS.ADDWISE]: {
//     src: process.env.PUBLIC_URL + '/videos/addwise-demo.mov'
//   },
//   [PROJECT_KEYS.FILMBOX]: {
//     src: process.env.PUBLIC_URL + '/videos/filmbox-demo.gif'
//   },
//   [PROJECT_KEYS.SHRILLING_CHICKEN]: {
//     src: process.env.PUBLIC_URL + '/videos/shrilling-chicken.mov'
//   }
// }

function MobileVideo({ src }) {
  const [isLoaded, setLoaded] = useState(false);

  const handleLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <video src={src} preload={"auto"} autoPlay={true} loop={true} style={{ height: '100%', width: '100%' }} onLoad={handleLoad}/>
      {!isLoaded && <div style={{ position :"absolute", top: 100, width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Spinner/>
      </div>}
    </>
  );
}

function MobileImage({ src }) {
  const [isLoaded, setLoaded] = useState(false);

  const handleLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <img src={src} alt={"project display"} style={{ height: '100%', width: '100%' }} onLoad={handleLoad}/>
      {!isLoaded && <div style={{ position :"absolute", top: 100, width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Spinner/>
      </div>}
    </>
  );
}

const PROJECT_NOTES = {
  [PROJECT_KEYS.ADDWISE]: [
    'learnt project management',
    'learnt focusing MVP is key',
    'learnt iOS development',
    'learnt firebase'
  ],
  [PROJECT_KEYS.ISHELF]: [
    'improved iOS development',
  ],
  [PROJECT_KEYS.TRT_HABER]: [
    'learnt react-native',
    'built my first react-native application that displays news',
  ],
  [PROJECT_KEYS.FILMBOX]: [
    'experienced more with react-native',
    'used dynamic rendering determined by backend',
    'implemented custom video player',
    'learnt why redux might be important for some applications'
  ],
  [PROJECT_KEYS.SHRILLING_CHICKEN]: [
    'learnt complete process of publishing an application',
    'recorded chicken sounds :D',
  ],
  [PROJECT_KEYS.GOTUR]: [
    'worked with a team of 5',
    'built an application within 2 days in a hackathon',
    'awarded with 3rd place',
    'used google map'
  ],
  [PROJECT_KEYS.LANDMARK_RECOGNITION]: [
    'built an application within 2 days in a hackathon',
    'used google map'
  ],
  [PROJECT_KEYS.PROENTE]: [
    'managed two react-native application',
    'improved legacy code',
    'published new releases',
    'dealed with low memory/high rendering issues',
  ]
};

const persistWithQueryParamOptions = { useQuery: true };
function MobileProjects() {
  const [currentTechType, setTechType] = useStateWithLocalStorage('SELECTED_MOBILE_TECH_TYPE', MOBILE_TECH_TYPES.REACTNATIVE, persistWithQueryParamOptions);
  const [currentProjectKey, setCurrentProjectKey] = useStateWithLocalStorage('SELECTED_MOBILE_PROJECT_KEY', PROJECT_KEYS.GOTUR, persistWithQueryParamOptions);
  const buttonGroupOptions = [{ key: MOBILE_TECH_TYPES.REACTNATIVE, label: 'react-native' }, { key: MOBILE_TECH_TYPES.IOS, label: 'iOS' }];
  const projectButtonGroupOptions = {
    [MOBILE_TECH_TYPES.IOS]: [{
      key: PROJECT_KEYS.GOTUR,
      label: 'Götür'
    },
      {
        key: PROJECT_KEYS.ADDWISE,
        label: 'Addwise'
      },
      {
        key: PROJECT_KEYS.ISHELF,
        label: 'iShelf'
      },
      {
        key: PROJECT_KEYS.SHRILLING_CHICKEN,
        label: 'Shrilling Chicken'
      },
      {
        key: PROJECT_KEYS.LANDMARK_RECOGNITION,
        label: 'Landmark recognition',
      }],
    [MOBILE_TECH_TYPES.REACTNATIVE]: [
      {
        key: PROJECT_KEYS.FILMBOX,
        label: 'Filmbox',
      },
      {
        key: PROJECT_KEYS.TRT_HABER,
        label: 'TRT Haber',
      },
      {
        key: PROJECT_KEYS.PROENTE,
        label: 'Proente',
      }
    ]
  };
  return (
    <>
      <ButtonGroup selectedKey={currentTechType} color={colors.secondary} style={{ marginBottom: '1.5em' }} options={buttonGroupOptions} onClick={(option) => setTechType(option.key)}/>
      <ButtonGroup selectedKey={currentProjectKey} color={colors.secondary} style={{ marginBottom: '1.5em' }} options={projectButtonGroupOptions[currentTechType]} onClick={(option) => setCurrentProjectKey(option.key)}/>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center', flexWrap: 'wrap' }}>
        <div style={{ position: 'relative', maxWidth: 400, overflow: 'hidden', ...boxShadow }}>
          {MOBILE_PROJECT_INFO_MAPPED_BY_PROJECT_KEY[currentProjectKey]?.type === 'video' && <MobileVideo key={MOBILE_PROJECT_INFO_MAPPED_BY_PROJECT_KEY[currentProjectKey]?.src} src={MOBILE_PROJECT_INFO_MAPPED_BY_PROJECT_KEY[currentProjectKey]?.src} />}
          {MOBILE_PROJECT_INFO_MAPPED_BY_PROJECT_KEY[currentProjectKey]?.type === 'img' && <MobileImage key={MOBILE_PROJECT_INFO_MAPPED_BY_PROJECT_KEY[currentProjectKey]?.src} src={MOBILE_PROJECT_INFO_MAPPED_BY_PROJECT_KEY[currentProjectKey]?.src} />}
          {MOBILE_PROJECT_INFO_MAPPED_BY_PROJECT_KEY[currentProjectKey]?.type === 'hidden' && <div style={{ height: 500, width: 400, maxWidth: '100%', backgroundColor: 'white', marginTop: 0, paddingTop: 50 }}><h3 style={{ marginTop: 0}}>Hidden</h3><div>Sorry, I can't show the application due to privacy issues.</div></div>}
        </div>
        <div style={{ marginLeft: '2em', maxWidth: 300, textAlign: 'start', color: 'white' }}>
          <ProjectNotes notes={PROJECT_NOTES[currentProjectKey]}/>
          <ProjectInfoDisplay project={MOBILE_PROJECT_INFO_MAPPED_BY_PROJECT_KEY[currentProjectKey] ?? {}} />
        </div>
      </div>
    </>
  );
}

export default MobileProjects;
