import React from 'react';

const BACKEND_TECH_KEYS = {
  NODEJS: 'NODEJS',
  DOCKER: 'DOCKER',
  REDIS: 'REDIS',
  FIREBASE: 'FIREBASE',
  POSTGRESQL: 'POSTGRESQL',
};

const BACKEND_TECH_INFOS = {
  [BACKEND_TECH_KEYS.NODEJS]: {
    logoUrl: process.env.PUBLIC_URL + 'images/logo/nodejs-logo.png',
  },
  [BACKEND_TECH_KEYS.DOCKER]: {
    logoUrl: process.env.PUBLIC_URL + 'images/logo/docker-logo.png',
  },
  [BACKEND_TECH_KEYS.REDIS]: {
    logoUrl: process.env.PUBLIC_URL + 'images/logo/redis-logo.webp',
  },
  [BACKEND_TECH_KEYS.FIREBASE]: {
    logoUrl: process.env.PUBLIC_URL + 'images/logo/firebase-logo.png',
  },
  [BACKEND_TECH_KEYS.POSTGRESQL]: {
    logoUrl: process.env.PUBLIC_URL + 'images/logo/postgresql-logo.png',
  },
};

function BackendProjects() {
  return (
    <div style={{ color: 'white' }}>
      {Object.entries(BACKEND_TECH_INFOS).map(([k, v]) => {
        return <img key={k} src={v.logoUrl} style={{ width: 80, padding: 10 }} alt={"backend technology logo"}/>
      })}
      <h3>Those are the things that I use in the backend.</h3>
    </div>
  );
}

export default BackendProjects;
