import React, {useState} from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";
import { FaGithub, FaEnvelope, FaInstagram, FaBars, FaCircleNotch } from 'react-icons/fa';
import {colors} from "../constants";

const StyledList = styled.ol`
  font-size: 14px;
  list-style: none;
  display: flex;
  height: 54px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-width: 1px;
  border-color: ${colors.primaryLight};
  border-style: solid;
  @media (max-width: 768px) {
    display: ${props => props.noVertical === true ? '' : props.isDropdownVisible ? 'block' : 'none'};
    ${props => props.noVertical === true ? '' : 'position: absolute;'}
    text-align: left;
    top: 57px;
    z-index: 100;
  }
`;

const StyledListItemIcon = styled.li`
  padding: 0 14px;
  font-size: 24px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.ultramarine}; 
    text-decoration: underline;
    color: white;
  }
  height: 100%;
  line-height: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 8px;
`;

const StyledListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${props => props.isDropdownVisible ? '8px 14px' : '0 14px'};
  font-size: 15px;
  background-color: ${colors.primary};
  ${props => props.isActive && 
  `
  background-color: ${colors.primaryLight};
  color: white;
  `
  }
  
  cursor: pointer;
  // border-right-width: 1px;
  // border-right-color: ${colors.primaryLight};
  // border-right-style: solid;
  // display: inline;
  &:hover {
    ${props => props.isActive === false && `background-color: ${colors.ultramarine};`} 
    text-decoration: underline;
    color: white;
  }
  @media (max-width: 768px) {
    height: initial;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  
  background-color: ${colors.primary};
  color: white;
  overflow: hidden;
  width: 1040px;
  max-width: 100%;
  border-radius: 2px;
  margin: 10px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuButton = styled.button`
  display: none;
  line-height: 0;
  padding: 14px;
  background-color: white;
  color: black;
  @media (max-width: 768px) {
    display: block;
  }
`;

const SkipLink = styled.a`
  width: 0;
  color: ${colors.primaryLight};
  &:focus {
    width: initial;
    color: white;
    background-color: blue important!;
  }
`;

function NavBar() {
  const { pathname } = window.location;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  return (
    <Center>
      <Container>
        <MenuButton onClick={() => setIsDropdownVisible(prev => !prev)}>
          <FaBars />
        </MenuButton>
        <StyledList isDropdownVisible={isDropdownVisible}>
          <SkipLink href={"#mainContent"}><StyledListItem isDropdownVisible={isDropdownVisible}>Skip to content</StyledListItem></SkipLink>
          <Link to={'/'}><StyledListItem isActive={pathname === '/'} style={{ lineHeight: 0 }} isDropdownVisible={isDropdownVisible}><FaCircleNotch/></StyledListItem></Link>
          <Link to={'/about'}><StyledListItem isActive={pathname === '/about'} isDropdownVisible={isDropdownVisible}>me</StyledListItem></Link>
          <Link to={'/projects'}><StyledListItem isActive={pathname === '/projects'} isDropdownVisible={isDropdownVisible}>my projects</StyledListItem></Link>
          <Link to={'/open-source'}><StyledListItem isActive={pathname === '/open-source'} isDropdownVisible={isDropdownVisible}>open source</StyledListItem></Link>
          <Link to={'/used-libraries'}><StyledListItem isActive={pathname === '/used-libraries'} isDropdownVisible={isDropdownVisible}>libraries that I use</StyledListItem></Link>
          <Link to={'/thoughts'}><StyledListItem isActive={pathname === '/thoughts'} isDropdownVisible={isDropdownVisible}>some thoughts</StyledListItem></Link>
          {/*// github mail */}
        </StyledList>
        <StyledList noVertical={true}>
          <a href={'mailto:ismailnamdar@gmail.com'} style={{ height: '100%'}}>
            <StyledListItemIcon>
              <FaEnvelope />
            </StyledListItemIcon>
          </a>
          <a href={'http://instagram.com/i.namdar'} target={"_blank"} rel={"noopener noreferrer"} style={{ height: '100%'}}>
            <StyledListItemIcon>
              <FaInstagram />
            </StyledListItemIcon>
          </a>
          <a href={'https://github.com/ismailnamdar'} target={"_blank"} rel={"noopener noreferrer"} style={{ height: '100%'}}>
            <StyledListItemIcon>
              <FaGithub />
            </StyledListItemIcon>
          </a>
        </StyledList>
      </Container>
    </Center>
  );
}

export default NavBar;
