import React from 'react';
import styled from "styled-components";

const color = 'hsl(212,76%,85%)';
const Container = styled.blockquote`
  background-color: ${color};
  border-left: 5px solid hsl(204, 86%, 53%);
  padding: 1em 1.25em;
  color: black;
  margin: 0;
`;

function Note({ style, children }) {
  return (
    <Container style={style}>
      {children}
    </Container>
  );
}

export default Note;
