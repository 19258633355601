import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const useStateWithLocalStorage = (key, initialValue, options = {}) => {
  const history = useHistory();
  const { useQuery } = options;
  const params = new window.URLSearchParams(window.location.search);
  const paramValue = params.get(key);
  const val = JSON.parse(localStorage.getItem(key));
  const [value, setValue] = useState(useQuery ? (paramValue ?? val ?? initialValue) : (val ?? initialValue));

  useEffect(() => {
    if (options.useQuery === true) {
      const parameters = new window.URLSearchParams(window.location.search);
      parameters.set(key, value);
      history.push({ search: parameters.toString() });
    }
  }, [key, value, history, options]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
