import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import Home from "./pages/Home";
import Projects from "./pages/Projects";
import UsedLibraries from "./pages/UsedLibraries";
import OpenSource from "./pages/OpenSource";
import Thoughts from "./pages/Thoughts";
import About from "./pages/About";
import Laptop from "./pages/Laptop";
import WordControllerPage from "./pages/WordControllerPage";

function AppRouter () {
  return (
    <BrowserRouter>
      <Switch>
        <Route key={"home"} exact path={"/"} component={Laptop} />
        <Route key={"about"} exact path={"/about"} component={About} />
        <Route key={"projects"} exact path={"/projects"} component={Projects} />
        <Route key={"usedLibraries"} exact path={"/used-libraries"} component={UsedLibraries} />
        <Route key={"openSource"} exact path={"/open-source"} component={OpenSource} />
        <Route key={"thoughts"} exact path={"/thoughts"} component={Thoughts} />
        <Route key={"wordController"} exact path={"/word-controller"} component={WordControllerPage} />
      </Switch>
    </BrowserRouter>
  );
}
function App() {
  // useEffect(() => {
  //   const fetch2 = async () => {
  //     const response = await fetch("https://namdar.netlify.com");
  //     console.log(response);
  //   };
  //   fetch2();
  // }, []);
  return (
    <div className="App">
      <AppRouter/>
    </div>
  );
}

export default App;
