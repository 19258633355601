import React from 'react';
import NavBar from "../components/NavBar";
import styled from "styled-components";
import {colors} from "../constants";

const StyledDiv = styled.div`
  max-width: 600px;
  color: ${colors.ultramarine};
  font-size: 24px;
  background-color: white;
  padding: 20px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20vh;
`;

function Thoughts(props) {
  return (
    <div style={{
      width: '100%',
      minHeight: '100vh',
      // overflowY: 'scroll',
      backgroundColor: colors.primary,
    }}>
      <NavBar/>
      <main id={"mainContent"}>
        <Container>
          <div>
            <StyledDiv>
              Currently, I don't have anything.
              I am planning to write something about the future web and my experience in frontend as a frontend developer who started with react.
            </StyledDiv>
          </div>
        </Container>
      </main>
    </div>
  );
}

export default Thoughts;
