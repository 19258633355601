import React, {useCallback, useState} from 'react';
import ReactMarkdown from 'react-markdown'
import Browser, {Chrome, Firefox, Safari} from "react-browser-ui";
import styled from "styled-components";

import ButtonGroup from "./ButtonGroup";
import {BROWSER_TYPES, colors, PROJECT_KEYS} from "../constants";
import ProjectInfoDisplay from "./ProjectInfoDisplay";
import {useStateWithLocalStorage} from "../hooks";
import Spinner from "./Spinner";
import ProjectNotes from "./ProjectNotes";

const BROWSER_COMPONENTS = {
  [BROWSER_TYPES.CHROME]: Chrome,
  [BROWSER_TYPES.FIREFOX]: Firefox,
  [BROWSER_TYPES.SAFARI]: Safari,
};

const iframeStyle = { width: "100%", height: "calc(100% - 50px)", borderStyle: 'none', backgroundColor: 'white' };
const boxShadow = {
  'WebkitBoxShadow': '0px 0px 29px -4px rgba(0,0,0,0.75)',
  'MozBoxShadow': '0px 0px 29px -4px rgba(0,0,0,0.75)',
  'boxShadow': '0px 0px 29px -4px rgba(0,0,0,0.75)',
};

function BrowserFrame({ title, src, style }) {
  const [isLoaded, setLoaded] = useState(false);

  const handleLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <iframe title={title} src={src} style={style} onLoad={handleLoad}/>
      {!isLoaded && (<div style={{ position: 'absolute', top: 100, width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Spinner color={'black'}/>
      </div>)}
    </>
  )
}

const PROJECT_INFO_MAPPED_BY_PROJECT_KEY = {
  [PROJECT_KEYS.PROENTE]: {
    title: 'Proente',
    body: `
    I mainly worked on Prowmes product which is a Manufacturing Execution System (MES) solution. 
    I worked with NodeJS and Angular migration to React with new additional cool features. 
    I worked on complex data visualization with chart libraries and created dynamic dashboards. Our aim is to provide
    meaningful and configurable real time charts and components to our customers.
    Some of our customers are Pladis Ulker, Ford, Tofas, Setmedikal. Some of the
    technologies that I used in here are redux, hooks, context, graphql, apollo,
    react-grid-layout, antd, bizcharts.
    `
  },
  [PROJECT_KEYS.PRODUCT_LIST]: {
    title: 'Product list',
    body: 'This project is done under 8 hours. It has a complete frontend architecture setup in it.'
  },
  [PROJECT_KEYS.GITHUB_CLONE]: {
    title: 'Github clone',
    body: 'This project is done in 6-8 hours. It uses graphql api of the github to do all the pagination and display.'
  },
  [PROJECT_KEYS.BATMAN_TV]: {
    title: 'Batman TV Shows',
    body: 'This project is done in 6 hours. Main purpose of the project is to display just the batman tv shows but I added more flexibility to search from all the available shows. Project mostly focused on setting up frontend architecture as well as designing good UI and UX.'
  },
  [PROJECT_KEYS.WEB_ACCESSIBILITY_HELPER]: {
    title: 'Web Accessibility Helper',
    body: <>
      This project aims to make the web more accessible to people who have disabilities.
      <a className={"link ml-2"} style={{ color: 'cyan' }} href={'https://www.github.com/ismailnamdar/web-accessibility-helper'} target={"_blank"} rel={"noopener noreferrer"}>see more</a>
    </>
  },
  [PROJECT_KEYS.COURSE_PREREQUISITES]: {
    title: 'Course prerequisites',
    body: "When I was in college, I had a difficult time choosing which course is more important to take first because some courses depend on other courses. Since, some of the courses are not available in both semester, in order not to extend school a couple of years, students need to be very careful about choosing the right course in right semester. I built a course prerequisites chart for some of the faculties to solve that problem."
  },
  [PROJECT_KEYS.ACCES]: {
    title: 'Acces',
    body: "This application is terminated due to some obstacles we encounter. 'Acces' is a judge system where codes can be evaluated with the given input and output. This way, we are making the process of evaluation so much easier for the author. I designed and implemented the frontend structure with one of my colleague. Since backend is not available, signing in is unfortunately disabled. \n However, I can say at least we used `blueprintjs` as a UI library."
  }
};

const PROJECT_NOTES = {
  [PROJECT_KEYS.PROENTE]: [
    'migrated from angular to react',
    'built dynamic dashboards',
    'worked with chart libraries',
    'used nodejs',
    'used graphql/apollo',
    'worked with web sockets/live data',
    'experienced with react rendering behavior',
    'led a small team'
  ],
  [PROJECT_KEYS.PRODUCT_LIST]: [
    'used redux',
    'used redux-saga',
    'created complete frontend architecture'
  ],
  [PROJECT_KEYS.GITHUB_CLONE]: [
    'used graphql service of github',
    'created complete frontend architecture'
  ],
  [PROJECT_KEYS.BATMAN_TV]: [
    'experienced with designing',
  ],
  [PROJECT_KEYS.WEB_ACCESSIBILITY_HELPER]: [
    'learnt accessibility',
    'developed small accessibility helper',
    'created chrome extension'
  ],
  [PROJECT_KEYS.COURSE_PREREQUISITES]: [
    'done web crawling',
    'created course prerequisites chart',
    'used dynamic svg path',
  ],
  [PROJECT_KEYS.ACCES]: [
    'used blueprint ui library',
    'used react-pdf to show pdf on the page',
    'created CRUD operations'
  ]
};

const StyledDiv = styled.div`
  width: 700px;
  @media (max-width: 700px) {
    max-width: calc(100vw - 3em);
  }
`;

const ProjectButtonContainer = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: initial; 
  }
`;

const persistWithQueryParamOptions = { useQuery: true };

function WebProjects() {
  const [currentBrowserType, setBrowserType] = useStateWithLocalStorage('BROWSER_PREFERENCE', BROWSER_TYPES.CHROME, persistWithQueryParamOptions);
  const [activeTab, setActiveTab] = useStateWithLocalStorage('PROJECT_PREFERENCE', PROJECT_KEYS.PROENTE, persistWithQueryParamOptions);
  const { Tab, Divider } = BROWSER_COMPONENTS[currentBrowserType];
  const projectOptions = Object.entries(PROJECT_INFO_MAPPED_BY_PROJECT_KEY).map(([k, value]) => ({
    key: k,
    label: value.title,
  }));

  const tabEnd = (<Divider/>);
  return (
    <>
      <ButtonGroup selectedKey={currentBrowserType} color={colors.secondary} style={{ marginBottom: '1.5em' }} options={[{ key: 'chrome', label: 'Chrome' }, { key: 'firefox', label: 'Firefox' }, { key: 'safari', label: 'Safari' }]} onClick={(option) => setBrowserType(option.key)}/>
      <ProjectButtonContainer>
        <ButtonGroup selectedKey={activeTab} color={colors.secondary} style={{ marginBottom: '1.5em' }} options={projectOptions} onClick={(option) => setActiveTab(option.key)}/>
      </ProjectButtonContainer>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center', flexWrap: 'wrap' }}>
        <StyledDiv style={{ position: 'relative', height: 800, overflow: activeTab !== PROJECT_KEYS.WEB_ACCESSIBILITY_HELPER ? 'hidden' : 'scroll' , ...boxShadow }}>
          <Browser
            type={currentBrowserType}
            showHeader={false}
            activeTabKey={activeTab}
            style={currentBrowserType !== BROWSER_TYPES.SAFARI ? boxShadow : undefined}
            onChange={setActiveTab}
            tabEnd={tabEnd}>
            <Tab key={PROJECT_KEYS.PROENTE} title={"Proente"}>
              <div style={{ paddingTop: '2em', display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: 'white'  }}>
                <h1>Hidden</h1>
                <div style={{ padding: '0 40px 0 40px' }}>Unfortunately I can't show the UI here due to privacy issues. However, I can describe what we built!</div>
              </div>
            </Tab>
            <Tab key={PROJECT_KEYS.PRODUCT_LIST} title={"Product list"}>
              <BrowserFrame title="shop" src="https://sshop.netlify.com/" style={iframeStyle}/>
            </Tab>
            <Tab key={PROJECT_KEYS.BATMAN_TV} title={"Batman TV!"}>
              <BrowserFrame title="batmantv" src="https://batmantv.netlify.com/" style={iframeStyle}/>
            </Tab>
            <Tab key={PROJECT_KEYS.GITHUB_CLONE} title={"Github clone"}>
              <BrowserFrame title="githubclient" src="https://githubclient.netlify.com/" style={iframeStyle}/>
            </Tab>
            <Tab key={PROJECT_KEYS.WEB_ACCESSIBILITY_HELPER} title={"Web accessibility helper"}>
              <div style={{ overflowY: 'scroll', backgroundColor: 'white', textAlign: 'start', padding: 20, }}>
                <ReactMarkdown>
                {`
                # 🌐 web-accessibility-helper

It is a browser extension for making web more accessible.

## Getting Started

These instructions will get you a copy of the project up and running on your local machine.

## Features

* Increases contrast on texts.
* Adds \`onblur\` event identical to \`onmouseout\` if element has \`onmouseout\` event.
* Adds \`onfocus\` event identical to \`onmouseover\` if element has \`onmouseover\` event.
* Adds \`alt\` and \`longdesc\` attributes to images which do not have.
* Sets the \`lang\` and \`xml:lang\` attributes of html element by predicting the language of a document.
* Removes empty links.
* Changes \`<b/>\` to \`<strong/>\` in the document
* Changes \`<i/>\` to \`<em/>\` in the document
* Changes \`<font/>\` to \`<p/>\` in the document
* Adds \`<!DOCTYPE html "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtdd"\` to document if not exists.
* Adds a \`MutationObserver\` in order to listen and inform the user about interactive html changes. Added, removed or updated nodes will be read to user.

### Prerequisites

Install browserify if not exists.

\`\`\`
npm install -g browserify
\`\`\`

### Installing

- Clone or download the repository
- Change directory to repository directory
- Use browserify to bundle
\`\`\`
browserify index.js -o bundle.js
\`\`\`
- Add directory to your favorite browser's extension

## Built With

* [Browserify](http://browserify.org) - Browserify lets you require('modules') in the browser by bundling up all of your dependencies.

## License

This project is licensed under the GNU License - see the [LICENSE.md](LICENSE.md) file for details

                `}
              </ReactMarkdown>
              </div>
            </Tab>
            <Tab key={PROJECT_KEYS.COURSE_PREREQUISITES} title={"Course prerequisites"}>
              <BrowserFrame title="namdar"  src="https://namdar.netlify.com/" style={iframeStyle}/>
            </Tab>
            <Tab key={PROJECT_KEYS.ACCES} title={"Acces"}>
              <BrowserFrame title="acces" src="https://acces.netlify.app/" style={iframeStyle}/>
            </Tab>
          </Browser>
        </StyledDiv>
        <div style={{ marginLeft: '2em', maxWidth: 300, textAlign: 'start', color: 'white' }}>
          <ProjectNotes notes={PROJECT_NOTES[activeTab]}/>
          <ProjectInfoDisplay project={PROJECT_INFO_MAPPED_BY_PROJECT_KEY[activeTab]} />
        </div>
      </div>
    </>
  );
}

export default WebProjects;
