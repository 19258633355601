import React from 'react';
import styled from "styled-components";

const StyledSpan = styled.span`
  background-color: ${props => props.color};
  padding: 6px;
  border-radius: 2px;
  color: white;
  // border-style: solid;
`;

function Tag({ color, style, children }) {
  return (
    <StyledSpan color={color} style={style}>{children}</StyledSpan>
  );
}

export default Tag;
