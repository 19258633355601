import React from "react";
import { useHistory } from "react-router-dom";
import GithubPageCorner from "../components/GithubPageCorner";
import {colors} from "../constants";
import styled from "styled-components";
import WebProjects from "../components/WebProjects";
import {useStateWithLocalStorage} from "../hooks";
import MobileProjects from "../components/MobileProjects";
import BackendProjects from "../components/BackendProjects";
import GamingProjects from "../components/GamingProjects";
import NavBar from "../components/NavBar";

const Button = styled.button`
  background-color: ${colors.secondary};
  color: white; 
  width: 100%; 
  text-align: center; 
  padding: 20px;
  // outline: none;
  margin: 0;
  ${props => props.isActive ? 
    `Webkit-box-shadow: 0px 0px 29px -4px rgba(0,0,0,0.75);
    Moz-box-shadow: 0px 0px 29px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 29px -4px rgba(0,0,0,0.75);
    transform: scale(1.2);` : ''}
  &:hover {
    transform: scale(1.2);
  }
    
  @media (max-width: 400px) {
    padding: 8px;
  }
`;

const ProjectSelectionContainer = styled.div`
  width: 100px; 
  height: calc(80% - 65px); 
  position: fixed; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
  z-index: 100;
  @media (max-width: 768px) {
    width: 100%;
    height: unset;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-end;
    bottom: 0;
  }
`;

const PROJECT_DOMAINS = {
  WEB: 'WEB',
  MOBILE: 'MOBILE',
  BACKEND: 'BACKEND',
  GAMING: 'GAMING',
  HOBBY: 'HOBBY',
};

const PROJECT_DOMAIN_COMPONENTS = {
  [PROJECT_DOMAINS.WEB]: WebProjects,
  [PROJECT_DOMAINS.MOBILE]: MobileProjects,
  [PROJECT_DOMAINS.BACKEND]: BackendProjects,
  [PROJECT_DOMAINS.GAMING]: GamingProjects,
};

const persistWithQueryParamOptions = { useQuery: true };

function Projects () {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useStateWithLocalStorage('CURRENT_PROJECT_DOMAIN', PROJECT_DOMAINS.WEB, persistWithQueryParamOptions);
  const CurrentProjectComponent = PROJECT_DOMAIN_COMPONENTS[currentTab];

  return (
    <div style={{
      width: '100%',
      minHeight: '100vh',
      overflowY: 'scroll',
      backgroundColor: colors.primary,
    }}>
      {currentTab === PROJECT_DOMAINS.WEB && <GithubPageCorner link={"https://github.com/ismailnamdar/react-browser-ui"}/>}
      <NavBar />
      <main id={"mainContent"}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 80 }}>
          <ProjectSelectionContainer>
            <Button type={"button"} isActive={currentTab === PROJECT_DOMAINS.WEB} onClick={() => {
              history.push({ search: '' });
              setCurrentTab(PROJECT_DOMAINS.WEB)
            }}>Web</Button>
            <Button type={"button"} isActive={currentTab === PROJECT_DOMAINS.MOBILE} onClick={() => {
              history.push({ search: '' });
              setCurrentTab(PROJECT_DOMAINS.MOBILE)
            }}>Mobile</Button>
            <Button type={"button"} isActive={currentTab === PROJECT_DOMAINS.BACKEND} onClick={() => {
              history.push({ search: '' });
              setCurrentTab(PROJECT_DOMAINS.BACKEND)
            }}>Backend</Button>
            <Button type={"button"} isActive={currentTab === PROJECT_DOMAINS.GAMING} onClick={() => {
              history.push({ search: '' });
              setCurrentTab(PROJECT_DOMAINS.GAMING)
            }}>Gaming</Button>
          </ProjectSelectionContainer>
          <div style={{ minHeight: '100vh', flex: 1, padding: '1.5em' }}>
            <CurrentProjectComponent/>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Projects;
