import React from 'react';
import styled from "styled-components";

import {useStateWithLocalStorage} from "../hooks";
import {colors, PROJECT_KEYS} from "../constants";
import ButtonGroup from "./ButtonGroup";
import DeSurProjectInfo from "./DeSurProjectInfo";
import ProjectInfoDisplay from "./ProjectInfoDisplay";
import ProjectNotes from "./ProjectNotes";

const boxShadow = {
  'WebkitBoxShadow': '0px 0px 29px -4px rgba(0,0,0,0.75)',
  'MozBoxShadow': '0px 0px 29px -4px rgba(0,0,0,0.75)',
  'boxShadow': '0px 0px 29px -4px rgba(0,0,0,0.75)',
};

const ProjectInfoDisplayContainer = styled.div`
  margin-left: 2em; 
  max-width: 300px;
  text-align: start;
  color: white;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const GAMING_PROJECT_INFO_MAPPED_BY_PROJECT_KEY = {
  [PROJECT_KEYS.RE_PAIR]: {
    title: 're-pair',
    body: "This game is developed under 48 hours in the Global Game Jam 2020. GGJ theme for that year was 'repair'. We made some wordplay and come up with 're-pair' game. It is a multi-player card game where you can gain some skills with pairing cads to kill your opponent. It is developed with 1 animator, 1 game sound maker and 3 developer.",
    src: process.env.PUBLIC_URL + 'videos/re-pair-demo.gif',
  },
  [PROJECT_KEYS.DE_SUR]: {
    title: 'de_sur',
    body: <>
      {'This is my Counter Strike Global Offensive level design. I spent many hours to accomplish this. I learnt a lot on the way about gaming, level design, optimization, AI bot behavior.'}
      <a href={"https://steamcommunity.com/sharedfiles/filedetails/?id=713801066"} target={"_blank"} rel={"noopener noreferrer"} style={{ marginLeft: 4, textDecoration: 'underline', color: 'cyan' }}>see more</a>
    </>,
  }
};

const PROJECT_NOTES = {
  [PROJECT_KEYS.RE_PAIR]: [
    'built a game within 2 days',
    'worked with a team of 5',
    'used unity game engine',
  ],
  [PROJECT_KEYS.DE_SUR]: [
    'learnt level design',
    'learnt AI bot behavior',
    'learnt asset render optimization',
    'learnt modelling',
    'learnt how to do a map',
  ]
};

const persistWithQueryParamOptions = { useQuery: true };

function GamingProjects() {
  const [currentProjectKey, setCurrentProjectKey] = useStateWithLocalStorage('SELECTED_GAMING_PROJECT_KEY', PROJECT_KEYS.RE_PAIR, persistWithQueryParamOptions);
  const buttonGroupOptions = [
    { key: PROJECT_KEYS.RE_PAIR, label: 're-pair' },
    { key: PROJECT_KEYS.DE_SUR, label: 'de_sur' },
  ];

  return (
    <>
      <ButtonGroup selectedKey={currentProjectKey} color={colors.secondary} style={{ marginBottom: '1.5em' }} options={buttonGroupOptions} onClick={(option) => setCurrentProjectKey(option.key)}/>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center', flexWrap: 'wrap' }}>
        <div style={{ maxWidth: 600, maxHeight: '100%', overflow: 'hidden', ...boxShadow }}>
          {currentProjectKey === PROJECT_KEYS.RE_PAIR && <img src={GAMING_PROJECT_INFO_MAPPED_BY_PROJECT_KEY[currentProjectKey]?.src} alt="project display" style={{ height: '100%', width: '100%' }}/>}
          {currentProjectKey === PROJECT_KEYS.DE_SUR && <DeSurProjectInfo />}
        </div>
        <ProjectInfoDisplayContainer>
          <ProjectNotes notes={PROJECT_NOTES[currentProjectKey]}/>
          <ProjectInfoDisplay project={GAMING_PROJECT_INFO_MAPPED_BY_PROJECT_KEY[currentProjectKey] ?? {}} />
        </ProjectInfoDisplayContainer>
      </div>
    </>
  );
}

export default GamingProjects;
