export const colors = {
  primary: "#212121",
  secondary: "#CF6766",
  primaryLight: "#424246",
  ultramarine: "#031424",
  link: "hsl(217, 71%, 53%)",
  info: "hsl(204, 86%, 53%)",
  success: "hsl(141, 71%, 48%)",
  warning: "hsl(48, 100%, 67%)",
  danger: "hsl(348, 100%, 61%)",
};

//
// "#5f5846"
// "#847c65"
// "#d7d7cb"
// "#8c9b96"
// "#525e5e"

export const BROWSER_TYPES = {
  CHROME: 'chrome',
  FIREFOX: 'firefox',
  SAFARI: 'safari',
};

export const PROJECT_KEYS = {
  // web
  WEB_ACCESSIBILITY_HELPER: 'WEB_ACCESSIBILITY_HELPER',
  PROENTE: 'PROENTE',
  PRODUCT_LIST: 'PRODUCT_LIST',
  GITHUB_CLONE: 'GITHUB_CLONE',
  BATMAN_TV: 'BATMAN_TV',
  COURSE_PREREQUISITES: 'COURSE_PREREQUISITES',
  ACCES: 'ACCES',
  // mobile
  TRT_HABER: 'TRT_HABER',
  FILMBOX: 'FILMBOX',
  ADDWISE: 'ADDWISE',
  ISHELF: 'ISHELF',
  SHRILLING_CHICKEN: 'SHRILLING_CHICKEN',
  GOTUR: 'GOTUR',
  LANDMARK_RECOGNITION: 'LANDMARK_RECOGNITION',
  // gaming
  EEB: 'EEB',
  DE_SUR: 'DE_SUR',
  RE_PAIR: 'RE_PAIR',
};
