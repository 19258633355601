import React from 'react';
import NavBar from "../components/NavBar";
import styled from "styled-components";
import {colors} from "../constants";
import OpenSourceCard from "../components/OpenSourceCard";

const Container = styled.div`
  width: 100%;
  background-color: ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const openSources = [
  {
    url: 'https://github.com/ismailnamdar/react-browser-ui',
    title: 'react-browser-ui',
    description: 'maintainer',
    // color: colors.ultramarine,
  },
  {
    url: 'https://github.com/processing/p5.js',
    title: 'p5.js',
    description: 'code contributor',
    // color: colors.primary,
  },
  {
    url: 'https://github.com/excalidraw/excalidraw',
    title: 'excalidraw',
    description: 'translation contributor'
  },
  {
    url: 'https://github.com/ismailnamdar/web-accessibility-helper',
    title: 'web-accessibility-helper',
    description: 'maintainer'
  },
];

function OpenSource() {
  return (
    <div style={{
      minHeight: '100vh',
      backgroundColor: colors.primary,
    }}>
      <NavBar />
      <main id={"mainContent"}>
        <Container>
          {openSources.map(({ url, title, description, color }) => <OpenSourceCard key={url} url={url} title={title} description={description} color={color} />)}
        </Container>
      </main>
    </div>
  );
}

export default OpenSource;
