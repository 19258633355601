import React, {useCallback, useEffect, useState} from 'react';
import "./Laptop.css";
import Browser, {Tab} from "react-browser-ui";
import {BROWSER_TYPES, colors} from "../constants";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {useStateWithLocalStorage} from "../hooks";

Laptop.propTypes = {

};

const iframeStyle = { width: "100%", height: "calc(100% - 43px)", borderStyle: 'none', backgroundColor: 'white' };

const laptopColors = {
  frame: '#323232',
  main: '#a5a5a5',
  keyboard: 'black'
};

const BrowserContainer = styled.div`
  // transform: scale(0.9);
  height: 100%;
  overflow: hidden;
`;

const Monitor = styled.div`
  width: 300px;
  height: 160px;
  padding: 10px;
  border: 3px solid ${laptopColors.main};
  background-color: ${laptopColors.frame};
  border-radius: 10px;
  display: inline;
  transform: rotate(180deg);
  color: black;
`;

const PrimarySpan = styled.span`
  height: 100%;
  background-color: ${colors.link};
  padding: 10px;
  border-radius: 4px;
  color: white;
`;

const MonitorComponent = ({ browserVisible, setBrowserVisible}) => {
  const [activeTab, setActiveTab] = useStateWithLocalStorage('HOME_BROWSER_ACTIVE_TAB', "initial");

  const handleCloseBrowser = useCallback(() => {
    setBrowserVisible(false);
  }, [setBrowserVisible]);

  return (
    <Monitor>
      {browserVisible ? (
        <BrowserContainer>
          <Browser
            type={BROWSER_TYPES.CHROME}
            showHeader={false}
            activeTabKey={activeTab}
            // // style={activeTab !== BROWSER_TYPES.SAFARI ? boxShadow : undefined}
            onChange={setActiveTab}
            // // tabEnd={tabEnd}
            onClose={handleCloseBrowser}
            onMinifyClick={handleCloseBrowser}
            onFullscreenClick={handleCloseBrowser}
          >
            <Tab key={'initial'} title={"Welcome!"}>
              <div style={{
                paddingTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 50px)',
                backgroundColor: 'white'
              }}>
                <h3>Welcome to my website!</h3>
                <Link to={'/about'} className={"mt-2"}><PrimarySpan>Start exploring!</PrimarySpan></Link>
              </div>
            </Tab>
            <Tab key={'myWebsite'} title={"Ismail's Website"}>
              <iframe title="my-website" src={`${process.env.PUBLIC_URL}/about`} style={iframeStyle}/>
            </Tab>
          </Browser>
        </BrowserContainer>) : (
        <div style={{
          // height: 'calc(100% - 50px)',
          // maxHeight: '100%',
        }}>
          <img
            role="button"
            tabIndex={0}
            src={process.env.PUBLIC_URL + 'images/mac-desktop.png'}
            width={'100%'}
            alt={"mac desktop background"}
            onClick={() => setBrowserVisible(true)} />
        </div>
      )}
    </Monitor>
  )
};

const KeyboardKey = styled.div`
  flex: 1;
  padding: 1px;
  font-size: 10px;
  text-align: center;
  margin: 2px;
  background-color: ${laptopColors.keyboard};
  ${props => props.active && 'transform: scale(0.9);' } 
  color: white;
  
  height: 16px;
  // border-bottom: 16px solid black;
  // border-left: 2px solid ${laptopColors.main};
  // border-right: 2px solid ${laptopColors.main};
`;

const KeyboardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Trackpad = styled.div`
  width: 50%;
  border-radius: 6px;
  border: 1px solid black;
  height: 60px;
  margin-top: 5px;
`;

function Laptop(props) {
  const [state, setState] = useState();
  const [folded, setUnfold] = useState(true);
  const [browserVisible, setBrowserVisible] = useState(false);

  useEffect(() => {
    const listener = (event) => {
      setState(event.key);
    };
    const keyUpListener = (event) => {
      setState(prev => prev === event.key ? null : prev);
    };
    document.addEventListener('keydown', listener);
    document.addEventListener('keyup', keyUpListener);

    return () => {
      document.removeEventListener('keypress', listener);
      document.removeEventListener('keyup', keyUpListener);
    }
  }, []);

  const handleFold = useCallback(() => {
    setUnfold(prev => {
      const newValue = !prev;

      if (newValue === false && browserVisible === false) {
        setTimeout(() => {
          setBrowserVisible(true);
        }, 1000)
      }

      return newValue;
    })
  }, [browserVisible, setBrowserVisible]);

  // Open laptop if it is not opened in 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setUnfold(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <div>
      <section id="default-example" className="default-example">
        {/*<div className={"point-light"} style={{ position: 'absolute', marginTop: '-150px' }}/>*/}
        <div id="example-element" className="transition-all"
             style={{
               position: 'absolute',
               transform: "rotate3d(1, 0, 0, 70deg) translate3d(0, 10px, -10px)",
             }}>
          <div role="button" tabIndex={0} className="face front pointer" onClick={handleFold}>
            <div style={{ flex: 1, padding: 10 }}>
              <KeyboardRow style={{ marginTop: 2 }}>
                {[..."§1234567890-="].map((key) => (<KeyboardKey key={key} active={state?.toUpperCase() === key}>
                  {key}
                </KeyboardKey>))}
              </KeyboardRow>
              <KeyboardRow>
                {[..."QWERTYUIOP[]"].map((key) => (<KeyboardKey key={key} active={state?.toUpperCase() === key}>
                  {key}
                </KeyboardKey>))}
              </KeyboardRow>
              <KeyboardRow>
                {[..."ASDFGHJKL;'\\"].map((key) => (<KeyboardKey key={key} active={state?.toUpperCase() === key}>
                  {key}
                </KeyboardKey>))}
              </KeyboardRow>
              <KeyboardRow>
                {[..."ZXCVBNM,./"].map((key) => (<KeyboardKey key={key} active={state?.toUpperCase() === key}>
                  {key}
                </KeyboardKey>))}
              </KeyboardRow>
              <KeyboardRow>
                <Trackpad/>
              </KeyboardRow>
            </div>
          </div>
          <div className="face back"/>
          <div className="face right"/>
          <div className="face left"/>
          <div className="face top"/>
          <div role="button" tabIndex={0} className="face bottom pointer" onClick={handleFold}/>
        </div>
        <div id="example-element" className="transition-all" style={!folded ? {  transform: "rotate3d(1, 0, 0, 180deg) translate3d(0, -4px, 0)"} : { transform: "rotate3d(1, 0, 0, 70deg) translate3d(0, 6px, 0)" }}>
          <div role="button" tabIndex={0} className="face front pointer" onClick={handleFold}>
            <div className={"circle"}/>
          </div>
          <div className="face back">
            <MonitorComponent browserVisible={browserVisible} setBrowserVisible={setBrowserVisible}/>
          </div>
          <div className="face right"/>
          <div className="face left"/>
          <div className="face top"/>
          <div role="button" tabIndex={0} className="face bottom pointer" onClick={handleFold}/>
        </div>
      </section>
    </div>
  );
}

export default Laptop;
