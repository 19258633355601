import React from 'react';
import styled from "styled-components";

const StyledSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: 96px;
  height: 32px;
  
  & > div > div {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #fff;
    animation: scale-up 1s infinite;
  }
  
  & div:nth-child(1) {
    left: 8px;
    animation-delay: -0.20s;
  }
  
  & div:nth-child(2) {
    left: 40px;
    animation-delay: -0.10s;
  }
  & div:nth-child(3) {
    left: 72px;
    animation-delay: 0;
  }
  
  @keyframes scale-up {
    0% {
      transform: scale(1.7 / 1);
    }
    50% {
      transform: scale(1.7);
    }
    100% {
      transform: scale(1.7 / 1);
    }
  }
`;

function Spinner({ color }) {
  return (
    <StyledSpinner>
      <div>
        <div style={{ backgroundColor: color }}/>
        <div style={{ backgroundColor: color }}/>
        <div style={{ backgroundColor: color }}/>
      </div>
    </StyledSpinner>
  );
}

export default Spinner;
