import React from 'react';
import Note from "./Note";

ProjectNotes.propTypes = {

};

function ProjectNotes({ notes }) {
  if (!Array.isArray(notes)) return null;

  return (
    notes.map((note) => <Note key={note} style={{ marginBottom: '0.2em', fontWeight: 'bold' }}>
      {note}
    </Note>)
  )
}

export default ProjectNotes;
