import React from 'react';
import styled from "styled-components";
import {colors} from "../constants";
import Tag from "./Tag";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  max-width: 100%;
  width: 400px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.primary};
  background-color: white;
  &:hover {
    background-color: ${colors.secondary};
  }
`;

function OpenSourceCard({ url, title, color, description }) {
  return (
    <StyledDiv>
      <a href={url} target="_blank" rel={"noopener noreferrer"}><img src={process.env.PUBLIC_URL + 'images/github-logo.png'} alt="github logo" style={{ width: 64 }}/></a>
      <h3 style={{ textAlign: 'start', marginLeft: 8 }}>{title}</h3>
      <Tag color={color ?? colors.secondary} style={{ marginLeft: 8 }}>{description}</Tag>
    </StyledDiv>
  );
}

export default OpenSourceCard;
